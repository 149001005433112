<template>
  <div class="userManage">
    <van-pull-refresh v-model="refresh" @refresh="onRefresh">
      <user-info :userData="userData" :shopData="shopData"></user-info>
      <my-shop
        :userData="userData"
        :shopData="shopData"
        :shopAuth="shopAuth"
      ></my-shop>
      <manage></manage>
    </van-pull-refresh>
  </div>
</template>
<script>
import UserInfo from "./components/UserInfo";
import myShop from "./components/myShop";
import Manage from "./components/Manage";
import { mapMutations } from "vuex";
import { SET_AUTH } from "@/store/mutation-types";
import { PullRefresh } from "vant";

export default {
  name: "UserManage",
  components: {
    UserInfo,
    myShop,
    Manage,
    "van-pull-refresh": PullRefresh,
  },
  data() {
    return {
      refresh: false,
      loading: true,
      userData: {},
      shopAuth: {},
      shopData: {
        ShareProductCount: 0,
        CustomerCount: 0,
        ShopOrderCount: 0,
        ShopVisitor: 0,
      },
    };
  },
  mounted() {
    let shopAuth = JSON.parse(localStorage.getItem("shopAuth"));
    this.shopAuth = shopAuth;
    this.getUserInfo();
  },
  methods: {
    onRefresh() {
      this.refresh = true;
      this.getUserInfo(true)
        .then(() => {
          this.refresh = false;
        })
        .catch(() => {
          this.refresh = false;
        });
    },
    getUserInfo(hideLoading = false) {
      return new Promise((resolve, reject) => {
        if (!hideLoading) {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
        }
        let param = {};
        this.$api.user
          .loadUserInfo(param)
          .then((res) => {
            this.$commonEnv.commonAction(this.$commonEnv.actionType.setAlias, {
              alias: res.data.ACId,
            });
            this.userData = res.data;
            this.SET_AUTH({
              ShopId: res.data.ShopId,
              IsShopCheck: res.data.IsShopCheck,
              IsShopSubmit: res.data.IsShopSubmit,
            });
            this.$commonMethod.checkAuth(res.data);
            this.getShopInfo(res.data.ShopId, res.data.UserId);

            if (!hideLoading) {
              this.$commonEnv.commonAction(
                this.$commonEnv.actionType.hideLoading
              );
            }
            this.loading = false;
            resolve();
          })
          .catch((error) => {
            this.isfirst = false;
            this.loading = false;

            if (!hideLoading) {
              this.$commonEnv.commonAction(
                this.$commonEnv.actionType.hideLoading
              );
            }
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showDialog,
              {
                msg: error.message,
                status: error.status,
              }
            );
            reject();
          });
      });
    },
    getShopInfo(ShopId, UserId) {
      this.$api.countData
        .countData_BIndex({ ShopId: ShopId, UserId: UserId })
        .then((res) => {
          this.shopData = res.data;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.isfirst = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    ...mapMutations([SET_AUTH]),
  },
};
</script>
<style lang="scss" scoped>
.userManage {
  width: 100%;
  .van-pull-refresh {
    overflow: unset;
  }
  .regBox {
    margin-top: 0.3rem;
    width: 100%;
    .regImg {
      width: 95%;
      margin: 0 auto;
      position: relative;
      .regImgIcon {
        width: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="manage">
    <div class="management">
      <p class="manageTitle">{{ $t.userInterpret.Manage }}</p>
    </div>

    <ul class="items">
      <li class="itemBox" @click="goSupplier">
        <figure class="itemImg">
          <img
            class="itemImgIcon"
            src="~assets/img/store/icon_my_supplier@2x.png"
          />
        </figure>
        <p class="itemTitle">{{ $t.userInterpret.MySupplier }}</p>
      </li>
      <li class="itemBox" @click="goSupport">
        <figure class="itemImg">
          <img
            class="itemImgIcon"
            src="~assets/img/store/icon_my_help@2x.png"
          />
        </figure>
        <p class="itemTitle">{{ $t.support.helpCenter }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Manage",
  data() {
    return {};
  },
  methods: {
    goSupplier() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "supplier",
        name: "supplier",
        query: [],
      });
    },
    goSupport() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "support",
        name: "Support",
        query: [],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.manage {
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  .management {
    @include publicFlex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 8px 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    .manageTitle {
      @include divPublic;
      color: #000;
      font-weight: bold;
    }
  }
  .items {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    @include publicFlex;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    .itemBox {
      text-align: center;
      max-width: 33.3%;
      list-style: none;
      @include divPublic;
      .itemImg {
        width: 45px;
        height: 45px;
        display: inline-block;
        margin: 0;
        .itemImgIcon {
          width: 100%;
        }
      }
      .itemTitle {
        @include divPublic;
        color: #666;
        font-size: 14px;
      }
    }
  }
}
</style>
